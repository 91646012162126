<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="notificationCount"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>
    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
          pill
          variant="light-success"
        >
          {{ notificationCount }} New
        </b-badge>
      </div>
    </li>
    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link
        v-for="notification in notifications"
        :key="notification.ID"
        @click="handleNotificationClick(notification)"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :variant="notification.message.type"
            >
              <feather-icon :icon="notification.message.icon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.message.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.message.subtitle }}</small>
        </b-media>
      </b-link>

      <!-- System Notification Toggler -->
      <div
        v-if="systemNotifications.length>0"
        class="media d-flex align-items-center"
      >
        <h6 class="font-weight-bolder mr-auto mb-0">
          System Notifications
        </h6>
        <b-form-checkbox
          :checked="true"
          switch
        />
      </div>
      <!-- System Notifications -->
      <b-link
        v-for="notification in systemNotifications"
        :key="notification.ID"
        @click="handleNotificationClick(notification)"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :variant="notification.message.type"
            >
              <feather-icon :icon="notification.message.icon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.message.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.message.subtitle }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
    >Read all notifications</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia,
  BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'
import useJwt from '@/auth/jwt/useJwt'
import constants from '@/constants/static.json'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      notifications: [],
      systemNotifications: [],
      notificationCount: 0,
      userData: useJwt.getUser(),
      timer: '',
    }
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: true,
    }

    return {
      perfectScrollbarSettings,
    }
  },
  // created() {
  //   if (this.userData) {
  //     this.fetchUserNotifications()
  //   }
  // },
  methods: {
    ...mapActions('user', ['fetchUserNotificationsByUserID', 'updateUserNotificationsByID']),
    filterNotificationsByType(data, type) {
      const filteredNotifications = data.filter(notification => notification.message_type === type)
      return [...filteredNotifications]
    },
    fetchUserNotifications() {
      this.systemNotifications = []
      this.notifications = []
      this.fetchUserNotificationsByUserID(this.userData.id).then(response => {
        if (response) {
          const { data } = response
          this.notifications = this.filterNotificationsByType(data, constants.NOTIFICATIONS_TYPE.USER)
          this.systemNotifications = this.filterNotificationsByType(data, constants.NOTIFICATIONS_TYPE.ADMIN)
          this.notificationCount = data.length
        }
      }).catch(() => {
      })
    },
    updateNotificationsByID(data) {
      this.updateUserNotificationsByID(data).then(response => {
        if (response) {
          this.fetchUserNotifications()
        }
      }).catch(() => {
      })
    },
    handleNotificationClick(data) {
      const id = data.message.params
      const name = data.message.targetRoute
      const targetRoute = {
        name,
        params: { id },
      }

      // Check if the current route is the same as the target route
      if (
        this.$route.name === targetRoute.name
    && JSON.stringify(this.$route.params) === JSON.stringify(targetRoute.params)
      ) {
        // Avoid redundant navigation

      } else {
        this.updateNotificationsByID({ ID: data.ID, record_status: 2 })
        // Navigate to the target route if it's not the current route
        this.$router.push(targetRoute)
      }
    },
  },
}
</script>

<style>

</style>
