<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <b-img
        :src="currentLocale.img"
        height="14px"
        width="22px"
        :alt="currentLocale.locale"
      />
      <span class="ml-50 text-body">{{ currentLocale.name }}</span>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="handleLanguage(localeObj.locale)"
    >
      <b-img
        :src="localeObj.img"
        height="14px"
        width="22px"
        :alt="localeObj.locale"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import constants from '@/constants/static.json'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  data() {
    return {
      userData: useJwt.getUser(),
      currentLocale: this.locales.find(l => l.locale === constants.LOCALE.ENGLISH),

    }
  },
  mounted() {
    if (this.userData) {
      this.handleLanguage(this.userData.language)
    }
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: 'en',
        img: require('@/assets/images/flags/en.png'),
        name: 'English',
      },
      {
        locale: 'sv',
        // eslint-disable-next-line import/no-unresolved
        img: require('@/assets/images/flags/sw.png'),
        name: 'Swedish',
      },
    ]
    /* eslint-disable global-require */

    return {
      locales,
    }
  },
  methods: {
    handleLanguage(locale) {
      if (locale) {
        this.currentLocale = this.locales.find(l => l.locale === locale)
        this.$i18n.locale = locale
      }
    },
  },
}
</script>
