<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template
      v-if="userData"
      #button-content
    >
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.fullName || userData.username }}
        </p>
        <span class="user-status">{{ userData.role }}</span>
      </div>
      <b-avatar
        size="40"
        :text="userData.text"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!userData.fullName"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <b-dropdown-item
      :to="{ name: 'my-profile'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>{{ $t('MY_PROFILE') }}</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item
      :to="{ name: 'user-settings' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>{{ $t('SETTINGS') }}</span>
    </b-dropdown-item>

    <b-dropdown-item
      :to="{ name: 'settings-faq' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="HelpCircleIcon"
        class="mr-50"
      />
      <span>{{ $t('FAQ') }}</span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>{{ $t('LOGOUT') }}</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: useJwt.getUser() || {},
    }
  },
  methods: {
    ...mapActions('user', ['userLogout']),
    async logout() {
      try {
        const response = await this.userLogout()
        if (!response) throw new Error('Logout failed in Vuex action')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Goodbye ${this.userData.fullName || this.userData.username}`,
            icon: 'LogOutIcon',
            variant: 'info',
            text: 'You have successfully logged out. We hope to see you again soon!',
          },
        })
      } catch (error) {
        console.error('Logout Error:', error)
      } finally {
        useJwt.removeUser()
        useJwt.removeToken()
        useJwt.removeContent()
        this.$router.push({ name: 'auth-login' })
      }
    },
  },
}
</script>
