// eslint-disable-next-line import/named
import { USER_ROLE } from '@/constants/static.json'
import { defaultDashboard, userDashboard } from './dashboard'
import { settingsAdmin, settingsCompanyAdmin, settingsUser } from './settings'
import content from './content'

// eslint-disable-next-line import/prefer-default-export
export const resolveMenu = userRoleId => {
  switch (userRoleId) {
    case USER_ROLE.ADMIN:
      return [...defaultDashboard, ...content, ...settingsAdmin]
    case USER_ROLE.COMPANY_ADMIN:
      return [...defaultDashboard, ...content, ...settingsCompanyAdmin]
    case USER_ROLE.PROJECT_ADMIN:
      return [...userDashboard, ...settingsUser]
    case USER_ROLE.PLAYER:
    case USER_ROLE.INSPECTOR:
    case USER_ROLE.ANALYZER:
      return [...userDashboard]
    default:
      return []
  }
}
